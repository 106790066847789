import React, { useState, type FC, type MouseEvent, useEffect, useMemo } from 'react';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MobileFooter } from './MobileFooter/MobileFooter';
import { useSnackbar } from '../Snackbar/Snackbar';
import { socket } from '../../web/socket'
import { toFixed, getCurrencySymbol, calculatePercentage } from '../../helpers/util';
import { FooterChatComponent } from '../LiveChatComponent/component/FooterChatComponent';
import { getMessage, type MessagesInterface, getChatMessages } from '../../redux/reducers/chat';
import { useWindowSize } from '../../hooks/useWindowSize';
import { selectIsRunningAdvertisement } from '../../redux/selectors/alertMessage';
import { TransactionModalContext } from '../TransactionModalContext/TransactionModalContext';
import { DepositComponent } from '../../pages/Accounts/DepositSection/DepositComponent';
import { type IAccount } from '../../redux/reducers/accounts';
import { SelectAccountHeader } from '../Header/SelectAccountHeader/SelectAccountHeader';
import { WithdrawalComponent } from '../../pages/Accounts/WithdrawalSection/WithdrawalComponent';
import { setShowFooter } from '../../redux/reducers/showFooter';

import style from './Footer.module.css';

interface InterfaceFooter {
  setOpenMenu: (el: boolean) => void
}

const soundMessageReceive = new Audio('/Sounds/MessageDelivered.mp3');

export const Footer: FC<InterfaceFooter> = ({ setOpenMenu }) => {
  const { handleOpen } = useSnackbar();

  const dispatch = useAppDispatch();
  const [pnl, setPnl] = useState<number>(0);
  const [margin, setMargin] = useState<number>(0);
  const [openLiveChat, setOpenLiveChat] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const [openWithdrawal, setOpenWithdrawal] = useState<boolean>(false);
  const [openSelectAccounts, setOpenSelectAccounts] = useState<boolean>(false);
  const [chosenSelectedAccount, setChosenSelectedAccount] = useState<IAccount | null>(null);
  const { _id: userId, brand, isChatEnabled = true } = useAppSelector((state) => state.user);
  const { showFooter } = useAppSelector((state) => state.showFooter);
  const { headerRates } = useAppSelector((state) => state.currencyRates);
  const { accountIdToPnl, accountIdToMargin } = useAppSelector((state) => state.socketData);
  const accounts = useAppSelector((state) => state.accounts);
  const chat = useAppSelector((state) => state.chat);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth <= 600;

  const unreadMessageCount = unreadMessages < 9 ? unreadMessages.toString() : '9+'

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  const equity = (selectedAccount?.balance ?? 0) + pnl + (selectedAccount?.credit ?? 0);
  const freeMargin = equity - margin;
  const marginLevel: number = calculatePercentage(Number(equity), Number(margin));

  const handleChatBtn = (event: MouseEvent<HTMLButtonElement>): void => {
    setOpenLiveChat((prevState) => !prevState)
  }

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (chat.isOpenByClick) {
      if (isMobile) dispatch(setShowFooter(true));
      setOpenLiveChat(true);
    }
  }, [chat.isOpenByClick, isMobile]);

  useEffect(() => {
    if (selectedAccount !== null) {
      const accountId = selectedAccount._id;

      setMargin(accountIdToMargin[accountId] ?? 0);
    }
  }, [accountIdToMargin, selectedAccount]);

  useEffect(() => {
    if (selectedAccount !== null) {
      const accountId = selectedAccount._id;
      const symbol = selectedAccount.cfdAccountCurrency.symbol

      const convertedPnl = Number(accountIdToPnl[accountId] ?? 0) * (headerRates?.[symbol] ?? 1);

      setPnl(convertedPnl);
    }
  }, [accountIdToPnl, selectedAccount]);

  useEffect(() => {
    const getChat = (): void => {
      if (userId !== undefined && isChatEnabled) dispatch(getChatMessages(userId));
    };
    getChat();
  }, [userId, isChatEnabled]);

  useEffect(() => {
    if (chat._id !== undefined && userId !== undefined && isChatEnabled) {
      if (socket !== undefined && socket.connected) {
        socket.on(`getChatMessage/${chat._id}/${chat.user}`, (response: MessagesInterface) => {
          soundMessageReceive.play().catch((error) => {
            console.error('Failed to play sound:', error);
          });
          dispatch(getMessage(response));
        });
      }
    }

    return () => {
      socket.off(`getChatMessage/${chat._id}/${chat.user}`);
    };
  }, [chat, userId, isChatEnabled]);

  useEffect(() => {
    if (chat?.messages?.length > 0 && userId !== undefined) {
      const count = chat.messages.reduce((accumValue, { sender, isRead }) => {
        if (sender !== userId) {
          if (!isRead) {
            return accumValue + 1;
          }
        }
        return accumValue;
      }, 0);
      setUnreadMessages(count);
    }
  }, [chat?.messages, userId]);

  const TransactionModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openDeposit }
        setOpen={ setOpenDeposit }
        component={
          <DepositComponent setOpen={ setOpenDeposit } selectedAccount={ chosenSelectedAccount ?? selectedAccount } />
        }
    />
    )
  }, [openDeposit, selectedAccount, chosenSelectedAccount]);

  const WithdrawalModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openWithdrawal }
        setOpen={ setOpenWithdrawal }
        component={
          <WithdrawalComponent setOpen={ setOpenWithdrawal } selectedAccount={ chosenSelectedAccount ?? selectedAccount } />
        }
      />
    )
  }, [openWithdrawal, selectedAccount, chosenSelectedAccount]);

  const SelectAccountDropdown = useMemo(() => {
    return openSelectAccounts && <SelectAccountHeader
      setOpenSelectAccounts={ setOpenSelectAccounts }
      setOpenDeposit={ setOpenDeposit }
      setOpenWithdrawal={ setOpenWithdrawal }
      setChosenSelectedAccount={ setChosenSelectedAccount }
      selectedAccount={ selectedAccount }
    />
  }, [openSelectAccounts, selectedAccount, openSelectAccounts]);

  const copyEmail = (email: string): void => {
    navigator.clipboard.writeText(email);

    const openParams = {
      message: 'Email Copied',
      actionText: '',
      autoClose: true
    }

    handleOpen(openParams);
  }

  return (
    <>
      { !isMobile
        ? (
        <footer className={ style.footerWrapper } style={{ bottom: isRunningAdvertisement ? '48px' : '0' }}>
          <div className={ style.footerTitleContainer }>
            <p className={ style.footerTitle }>© {currentYear} {process.env.REACT_APP_BRAND_NAME}</p>
            {(brand?.supportEmail !== undefined) && <p onClick={() => { copyEmail(brand.supportEmail ?? ''); }} className={ style.footerTitleEmail }>{ brand?.supportEmail }</p>}
          </div>
          <div className={ style.footerInfoWrapper }>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.balance')}</p>
              <p className={ style.footerInfoValue }>{`${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}`}</p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('header.labels.credit')}</p>
              <p className={ style.footerInfoValue }>{`${symbolIcon} ${toFixed(selectedAccount?.credit ?? 0, 2)}`}</p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('header.labels.equity')}</p>
              <p className={ style.footerInfoValue }>
                {`${symbolIcon} ${toFixed(equity, 2)}`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.margin')}</p>
              <p className={ style.footerInfoValue }>
                {`${symbolIcon} ${toFixed(margin, 2)}`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.margin_level')}</p>
              <p className={ style.footerInfoValue }>
                {`${toFixed(marginLevel, 2)}%`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.free_margin')}</p>
              <p className={ style.footerInfoValue }>
                {`${symbolIcon} ${toFixed(freeMargin, 2)}`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('header.labels.pnl')}</p>
              <p className={ style.footerInfoValue } style={{ color: pnl >= 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }}>
                {`${symbolIcon} ${toFixed(pnl, 2)}`}
              </p>
            </div>
          </div>
          <div className={ style.footerContainer }>
            {
              isChatEnabled &&
              (
                <FooterChatComponent onClickHandler={ handleChatBtn } open={ openLiveChat } unreadMessageCount={ unreadMessageCount } setOpen={ setOpenLiveChat } setOpenDeposit={ setOpenDeposit }/>
              )
            }
          </div>
        </footer>
          )
        : (
            showFooter && (
              <MobileFooter
                openLiveChat={ openLiveChat }
                setOpenLiveChat={ setOpenLiveChat }
                handleChatBtn={ handleChatBtn }
                setOpenMenu={ setOpenMenu }
                unreadMessageCount={ unreadMessageCount }
                setOpenDeposit={setOpenDeposit}
                isChatEnabled={ isChatEnabled }
                setOpenSelectAccounts={setOpenSelectAccounts}
              />
            )
          )}
        { TransactionModal }
        { WithdrawalModal }
        { SelectAccountDropdown }
    </>
  );
}
