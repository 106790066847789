import React, { type FC, useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getOthers, searchOthers } from '../../../../redux/reducers/psp';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import style from './OtherDepositComponent.module.css';
import { t } from 'i18next';
import { SearchInputComponent } from '../../../../components';
import { useDebounce } from '../../../../hooks/useDebounce';
import { setOpenByClick } from '../../../../redux/reducers/chat';
import { type InterfaceDepositComponent } from '../../../../interfaces/DepositComponentInterface';

export const OtherDepositFormComponent: FC<InterfaceDepositComponent> = ({ setOpen }) => {
  const dispatch = useAppDispatch();
  const [searchedDepositMethod, setSearchedDepositMethod] = useState('');
  const [isPopularResults, setIsPopularResults] = useState(true);
  const { others } = useAppSelector((state) => state.psp);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user?.brandId !== undefined) {
      dispatch(getOthers({ brandId: user.brandId, countryCode: user.countryCode ?? '' }));
    }
  }, [user]);

  useDebounce({
    value: searchedDepositMethod,
    delay: 300,
    action: (value: string) => {
      setIsPopularResults(value === '')
      if (user?.brandId !== undefined) dispatch(searchOthers({ label: value, brandId: user.brandId, countryCode: user.countryCode ?? '' }))
    }
  });

  const handleChat = (): void => {
    dispatch(setOpenByClick(true));
    setOpen(false);
  }

  return (
    <div className={ style.wrapper }>
      <div className={ style.searchOtherWrapper }>
        <p className={ style.searchOtherWrapperText }>
          {t('deposit_modal.other_form.subtitle')}
        </p>
        <SearchInputComponent
          value={ searchedDepositMethod }
          setValue={ setSearchedDepositMethod }
          placeholder={ t('deposit_modal.other_form.searchPlaceholder') }
        />
      </div>
      <div className={ style.otherWrapper }>
        <p className={ style.otherWrapperText }>
          {
            isPopularResults
              ? t('deposit_modal.other_form.popular_result_title')
              : t('deposit_modal.other_form.search_result_title')
          }
        </p>
        <ul className={ style.popularWrapper }>
          { others.map((el) => (
              <li className={ style.popularContainer } key={ el._id }>
              <div className={ style.popular }>
                <div className={ style.imgContainer }>
                  <a href={ el.url } target='_blank' className={ style.popularLink } rel="noreferrer">
                    <img src={ `${process.env.REACT_APP_IMAGES_URL}${el.imageUrl}` } alt='' className={ style.img} />
                  </a>
                </div>
                <div className={ style.polularLinkContainer }>
                  <a href={ el.url } target='_blank' className={ style.popularLink } rel="noreferrer">{ el.label }</a>
                  <a href={ el.url } target='_blank' className={ style.popularBtn } rel="noreferrer">
                    <OpenInNewIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} fontSize='small'/>
                  </a>
                </div>
              </div>
            </li>
          )) }
        </ul>
        {
          user.isChatEnabled === true && (
            <p className={style.contactUs}>
              {t('deposit_modal.other_form.did_not_find_payment_service')}
              <button onClick={handleChat}>
                {t('deposit_modal.other_form.contact_support')}
              </button>
            </p>
          )
        }
      </div>
    </div>
  )
}
